<template>
  <div :class="'embed ' + this.class">
    <audio :data-setup="thisDataSetup" v-if="type === 'audio'" :id="'myAudio' + random"
           class="video-js vjs-default-skin"></audio>
    <video :data-setup="thisDataSetup" v-else :id="'myVideo' + random"
           class="video-js vjs-default-skin vjs-big-play-centered"></video>
    <div v-if="showInfo && song.id" class="mt-1">
      <p class="text-xl font-semibold">
        <router-link  :to="{name: 'SongDetail', params: {id: song.id, slug: song.slug, prefix: prefix}}">
          {{ song.title }}
        </router-link>
        <span class="ml-1 text-gray-500" v-if="song.subtitle">{{ song.subtitle }}</span>
      </p>
      <p v-if="song.fcats && song.fcats.data && song.fcats.data.length && (prefix === 'dan-ca' || prefix === 'thanh-vien-hat')"
          class="people-in-song">
        Thể loại:
        <fcat-of-song :fcats="song.fcats.data"/>
      </p>
      <p v-if="song.melodies && song.melodies.data && song.melodies.data.length && (prefix === 'dan-ca' || prefix === 'thanh-vien-hat')"
          class="people-in-song">
        Làn điệu:
        <melody-of-song :melodies="song.melodies.data"/>
      </p>
      <p v-if="song.sheet && song.sheet.composers && song.sheet.composers.data && song.sheet.composers.data.length">
        Sáng tác:
        <span class="people-in-song">
          <people-of-song :people="song.sheet.composers.data" type="composer"
                          class="font-semibold"/>
        </span>
        <span class="text-gray-500" v-if="song.sheet && song.sheet.year"> ({{ song.sheet.year }})</span>
      </p>
      <p v-if="song.poets && song.poets.data && prefix === 'tieng-tho'">
        Sáng tác:
        <span class="people-in-song">
          <people-of-song :people="song.poets.data" type="poet" class="font-semibold"/>
        </span>
        <span class="text-gray-500" v-if="song.year"> ({{ song.year }})</span>
      </p>
      <p v-if="song.recomposers && song.recomposers.data && song.recomposers.data.length && prefix === 'dan-ca'"
          class="people-in-song">
        Soạn lời:
        <people-of-song :people="song.recomposers.data" type="recomposer" class="font-semibold"/>
      </p>
      <p v-if="song.poets && song.poets.data && song.poets.data.length && prefix === 'bai-hat'"
          class="people-in-song">
        {{ song.lyric_type ? song.lyric_type : 'Thơ: ' }}{{ song.lyric_type && song.lyric_type.indexOf(':') === -1 ? ": " : " " }}
        <people-of-song :people="song.poets.data" type="poet" class="font-semibold"/>
      </p>
      <p v-if="song.artists && song.artists.data">
        Trình bày:
        <span class="people-in-song">
          <people-of-song :people="song.artists.data" type="artist"
                          class="font-semibold"/>
        </span>
        <span class="text-gray-500" v-if="song.record_year"> ({{ song.record_year }})</span>
      </p>
      <p v-if="song.users && song.users.data">
        Trình bày:
        <span class="people-in-song">
          <users-of-song :people="song.users.data" class="font-semibold"/>
        </span>
        <span class="text-gray-500" v-if="song.record_year"> ({{ song.record_year }})</span>
      </p>
    </div>
  </div>
</template>

<script>
import 'video.js/dist/video-js.min.css';
import videojs from 'video.js';
import {
  getPluralFromSongPrefix, getSongFieldsByType
} from "../core/services/utils.service";
import ApiService from "../core/services/api.service";
import PeopleOfSong from "./song/PeopleOfSong";
import UsersOfSong from "./song/UserOfSong";
import FcatOfSong from "./song/FcatOfSong";
import MelodyOfSong from "./song/MelodyOfSong";

export default {
  name: "Embed",
  components: {PeopleOfSong, UsersOfSong, FcatOfSong,MelodyOfSong},
  props: {
    src: String,
    showInfoProp: Boolean,
    unique: {
      type: String,
      default: ""
    },
    dataSetup: {
      type: String,
      default: '{"poster": "https://static.bcdcnt.net/assets/images/bcdcnt-bg.png", "autoplay": false, "loop": false, "controls": true, "bigPlayButton": true, "sources": []}'
    }
  },
  data() {
    return {
      type: "audio",
      class: String,
      player: null,
      random: "",
      thisDataSetup: null,
      song: {},
      showInfo: false,
      prefix: ""
    }
  },
  methods: {
    reRender(nextTick = true) {
      if (!this.source) {
        return;
      }

      this.random = this.unique;
      this.class = this.$route.query.class || "";
      let ext = this.source.split('.').pop();
      let ref = "";
      let mimeType = "";

      if (ext === "mp3") {
        this.type = "audio";
        ref = "myAudio" + this.random;
        mimeType = "audio/mp3";
      } else {
        this.type = "video";
        ref = "myVideo" + this.random;
        mimeType = "video/mp4";
      }

      if (nextTick) {
        this.$nextTick(() => {
          this.loadPlayer(ref, mimeType);
        })
      } else {
        this.loadPlayer(ref, mimeType);
      }
    },
    loadPlayer(ref, mimeType) {
      this.player = videojs(ref);
        if (this.type === 'audio') {
          if (this.poster) {
            this.player.poster(this.poster);
          }
        } else {
          this.player.poster(null);
        }
        this.player.src({src: this.source, type: mimeType});
        this.player.load();
    },
    loadSong(src) {
      const regex = /(localhost:8080|bcdcnt.net|test.bcdcnt.net|beta.bcdcnt.net)\/(bai-hat|dan-ca|khi-nhac|thanh-vien-hat|tieng-tho)\/([a-z0-9-]+)-([0-9]+).html$/gm;
      let m;

      while ((m = regex.exec(src)) !== null) {
          if (m.index === regex.lastIndex) {
              regex.lastIndex++;
          }
          
          m.forEach((match, groupIndex) => {
              if (groupIndex === 2) {
                this.prefix = match;
              } else if (groupIndex === 4) {
                this.id = match;
              }
          });
      }

      let plural = getPluralFromSongPrefix(this.prefix);
      let fields = getSongFieldsByType(plural);

      let query = `query($id: ID!) {
        ${plural}(id: $id) {
          id
          title
          slug
          subtitle
          thumbnail {
            url
          }
          file {
            video_url
            audio_url
          }
          ${fields}
          sheet {
            id
            composers(first: 20, orderBy: [{column: "sheet_composer.order", order: ASC}]) {
              data {
                id
                title
                slug
              }
            }
          }
        }
      }`;

      ApiService.graphql(query, {id: this.id})
          .then(({data}) => {
            if (data.data && data.data[plural] && data.data[plural].file) {
              this.source = data.data[plural].file.audio_url;
              if (data.data[plural].thumbnail) {
                this.poster = data.data[plural].thumbnail.url;
              }
              this.song = data.data[plural];
              this.reRender(true);
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
  },
  updated() {
    this.reRender(false);
  },
  created() {
    this.thisDataSetup = this.dataSetup;
    this.showInfo = this.showInfoProp;
  },
  mounted() {
    let src = this.$route.query.src || this.src;
    if (this.$route.query.showInfo) {
      this.showInfo = true;
    }
    if (src.indexOf(".html") > -1) {
      this.loadSong(src);
    } else {
      this.source = src;
      this.reRender(true);
    }
  },
  watch: {
    dataSetup: {
      handler(newVal) {
        this.thisDataSetup = newVal;
      }
    },
    thisDataSetup: {
      handler() {
        this.reRender(true);
      }
    }
  }
}
</script>
